<template>
  <v-card class="profile-info-container pilot-informations">
    <v-toolbar class="profile-info-toolbar">
      <v-btn @click.prevent="$router.go(-1)" class="btn-back">
        <v-icon>mdi-chevron-left</v-icon>
        <span class="ml-2">Back</span>
      </v-btn>
    </v-toolbar>
    <v-card class="profile-info-card">
      <div class="font-20 fw-500 text-left mb-4">
        Pilot Handbook
      </div>
      <v-row no-gutters class="informations-card-container">
        <v-col v-for="(data, index) in menus" cols="6" class="align-self-center col-information" :key="index">
          <v-card class="news-info-card" @click.prevent="data.is_coming_soon ? comingSoon() : data.external_link ? openLink(data.link) : $router.push(data.link)">
            <img :src="getImage(data.image)">
            <div>
              <div>
                {{ data.title }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>
<script>
import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'Information',
  data: (vm) => ({
    menus: [
      {
        link: 'https://www.bmkg.go.id/cuaca/prakiraan-cuaca-indonesia.bmkg?lang=EN',
        image: 'weather.jpg',
        title: 'Weather',
        external_link: true
      },
      {
        link: 'https://www.windy.com/-Temperature-temp',
        image: 'windy.jpg',
        title: 'Windy.com',
        external_link: true
      },
      {
        link: 'https://www.notams.faa.gov/dinsQueryWeb/',
        image: 'developing.svg',
        title: 'NOTAM',
        external_link: true
      },
      {
        link: 'https://drive.google.com/drive/folders/1HRN7itYsnUKXyp6CM4qL06jf6o5MFmcr',
        image: 'notes.jpg',
        title: 'NOTOC',
        external_link: true
      },
      {
        link: 'contact',
        image: 'contacts.jpg',
        title: 'Contacts',
        external_link: false
      },
      {
        link: 'https://drive.google.com/drive/folders/1kTIPxQSMekC2RKOQ0zTmcOF_CKsv8CZT',
        image: 'route-guide.jpg',
        title: 'Routes Guide',
        external_link: true
      },
      {
        link: 'airports',
        image: 'airports.jpg',
        title: 'Airports',
        external_link: false
      },
      // {
      //   link: '',
      //   image: 'developing.svg',
      //   title: 'Safety Reports',
      //   external_link: false,
      //   is_coming_soon: true
      // },
      {
        link: 'https://wa.me/628112128403',
        image: 'travel.jpg',
        title: 'Travel',
        external_link: true
      }
    ]
  }),
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })
  },
  methods: {
    comingSoon () {
      this.$toastNotif({
        message: 'Exciting New Feature on the Horizon: Stay Tuned for the Big Reveal!'
      })
    },
    openLink (link) {
      this.$SweetAlert({
        title: 'Leaving Forpilots?',
        caption: 'You will be redirected into external web sites.',
        confirmText: 'Continue',
        onConfirm: () => { window.open(link, 'parent') }
      })
    },
    getImage (fileName) {
      return require(`@/assets/media/${fileName}`)
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/global-vars';
@import '../assets/scss/fonts';

.pilot-informations{
  overflow: hidden;
  .informations-card-container{
    height: calc(100vh - 280px);
    overflow-y: auto;
    border-radius: 20px;
    &::-webkit-scrollbar{
      width: 0;
    }
    .col-information{
      padding-bottom: 26px;
      &:nth-child(odd){
        padding-right: 8px;
      }
      &:nth-child(even){
        padding-left: 8px;
      }
      .news-info-card {
        position: relative;
        height: 100px;
        width: 100%;
        border-radius: 12px !important;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.35) !important;
        .v-ripple__container{
          display: none !important;
        }
        >img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          filter: brightness(.75);
          object-fit: cover;
          border-radius: inherit;
          z-index: 2
        }
        >div {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 8px 12px;
          text-align: left;
          background: $primary;
          background: linear-gradient(0deg, $primary 0%, #16244780 75%, #00000000 100%);
          z-index: 3;
          >div {
            color: $white;
            @include font-size(16px);
            font-weight: 500;
            @include line-clamp(2);
          }
        }
      }
    }
  }
}
</style>
